<template>
    <button :class="buttonclass" type="button" @click="$emit('click')" :disabled="disabled">
        <div >
            <p class="buttontext">{{toplabel}}</p>
        </div>       
    </button>
</template>

<script>
export default {
    name:'smallbutton',
    props:{
        toplabel:{
            type:String,
            
        },
        buttonclass:{
          type:String,
          default:"button buttonprimary"
        },
        disabled:{
          type:Boolean,
          default:false
        }
        
    }
}
</script>

<style scoped>
.buttontext{
  margin:0px;
}

.button{
    
    
    border: none;
    border-radius:10px;
    width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
  text-decoration: none;
}
button:hover{
  font-weight: bolder;
    /* background-color:var(--highlight-color);
    color:var(--light-text-color); */
  cursor:pointer;
}
button:disabled{
  background-color:gray;
  color:var(--light-text-color);
}
.buttonprimary{
  background-color:var(--primary-color);
  color:var(--light-text-color);
}
.buttonhighlight{
  background-color:var(--highlight-color);
  color:var(--light-text-color);
}
.buttondisabled{
  background-color:var(--disabled-color);
  color:var(--light-text-color);
}
.buttonpay{
  background-color:var(--pay-color);
  color:var(--light-text-color);
}
.buttonmeal{
  background-color:var(--meal-color);
  color:var(--light-text-color);
}
</style>