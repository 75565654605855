<template>
<div>
    <div v-if="!isReady">
        <splash/>
    </div>

    <div :class="outerDivClass" :style="configStyle" id="outerdiv" v-show="isReady">
        <div class="header">
            <badgeburgermenu ref='topmenu'  :toplabel="titleText" @refresh="menuRefresh" @home="menuhome" @logout="logout()" 
                @time="showtime" @documents="showdocuments" @payaccount="payaccount" @addfunds="addfunds"/>
        </div>
        <div v-if="!loggedIn">
            
            <loginuser @loggedin='afterLogin()' ref='login'/>
        </div>
        <div v-if="loggedIn"  class="maindiv" >
            <!-- v-hammer:swipe.left="swipeleft" v-hammer:swipe.right="swiperight" -->
            <div v-if="currentNav=='front'"  class="frontDiv"  v-hammer:swipe.left="swipeleft" v-hammer:swipe.right="swiperight" >
               
                <div class="alert">
                    <div class="" v-if="hasAlerts">
                        <div v-for="alert in badgeData.BadgeAlertList" :key="alert.id" :value="alert.id" :class="alertClass(alert)" >

                            <label class="alerttextbold alertline1" v-html="alert.alertLine1"> </label>
                            <button class="dismissbutton alertdismissbutton" @click="dismissalert(alert)" v-if="alert.canBeDismissed">X</button>
                            <button class="tbuttondown alerttogglebutton" @click="togglealert(alert)" v-if="showdown(alert)">&#9660</button>
                            <button class="tbuttonup alerttogglebutton" @click="togglealert(alert)" v-if="showup(alert)">&#9650</button>
                            <label class="alerttext  alertline2" v-html="alert.alertLine2"></label>
 
                        </div>
                    </div>
                </div>

                <div class="student" v-if="hasBadgePerson">
                    <div >
                        <div class="horizFlexSpace" v-if="hasImagePerson">
                            <div class="vertFlex">
                                <img src="@/assets/dl.jpg" :class="licenseClass" @click="$refs.mymodal.openModal()" >
                                <label class="smalltext logolabel" v-if="BadgeDesign.showLicenseIcon">Driver's License</label>
                            </div>

                            <div :class="personImageClass" >
                                <img :src="imagePathPerson" class="studentimage"   v-if="capturedPersonURL==null" @click="startselfie()" />
                                <img alt="selfie image"  :src="capturedPersonURL" class="studentimage" v-if="capturedPersonURL!=null" @click="startselfie()" />
                            </div>
                            
                            <div class="vertFlex">
                                <img src="@/assets/vac2.png" :class="vaccineClass" @click="$refs.vaccine.openModal()" >
                                <label class="smalltext logolabel" v-if="BadgeDesign.showVaccineCardIcon">Vaccine</label>
                            </div>
                            
                        </div>
                        <!-- GET PIC  -->
                        <smallbutton toplabel="Update Photo" @click="startselfie()" v-if="allowCapturePerson"/>
                        <imageall ref="imgselfie" :allowFileSelect="allowFileSelect" :allowFileSelectIfNoCapture='allowFileSelectIfNoCapture' @saveimg="saveimg_selfie"  :showImage='showImagesInCapture' v-if="allowCapturePerson" />


                        <div class="horixFlex studentinfo" >

                            <label class="studentname">{{badgeData.person.firstName}}</label>
                            <label class="studentname">{{badgeData.person.lastName}}</label>
                            
                        
                        </div>
                        <div class="vertFlex">
                            <label for="" class="studentclass" v-if="badgeData.person.personRoleName!=null" >{{badgeData.person.personRoleName}}</label>
                            <label for="" class="studentclass" v-if="badgeData.person.programName!=null" >{{badgeData.person.programName}}</label>
                            <label for="" class="studentclass" v-if="badgeData.person.className!=null && showClass" >{{badgeData.person.className}}</label>
                        </div>
                        
                    </div>
                </div>

                <div :class="statusdivClass" v-if="showStatus">
                    <label class="statustext">{{badgeData.person.personStatusName}} </label>
                </div>
                <div class="status statusdivnegative" v-if="showInactive">
                    <label class="statustext">INACTIVE</label>
                </div>
                <div class="barcode" v-if="hasBadgePerson && badgeData.person.code!=null">
                    <div v-if="barcodeType!='QR'">
                        <barcode :value="badgeData.person.code" height="40px" :format="barcodeType"></barcode>
                    </div>
                    <div v-if="barcodeType=='QR'">
                        <qrcode-vue :value="badgeData.person.code" size="80" level="H"/>
                    </div>
                    
                    
                </div>

                
            </div>

            <!-- BACK --> 
            <!-- v-hammer:swipe.right="swiperight" -->
            <div v-if="currentNav=='back'" class="backDiv" v-hammer:swipe.right="swiperight" >
                <div class="alert">
                    <div class="" v-if="hasAlerts">
                        <div v-for="alert in badgeData.BadgeAlertList" :key="alert.id" :value="alert.id" :class="alertClass(alert)" >

                            <label class="alerttextbold alertline1" v-html="alert.alertLine1"> </label>
                            <button class="dismissbutton alertdismissbutton" @click="dismissalert(alert)" v-if="alert.canBeDismissed">X</button>
                            <button class="tbuttondown alerttogglebutton" @click="togglealert(alert)" v-if="showdown(alert)">&#9660</button>
                            <button class="tbuttonup alerttogglebutton" @click="togglealert(alert)" v-if="showup(alert)">&#9650</button>
                            <label class="alerttext  alertline2" v-html="alert.alertLine2"></label>
 
                        </div>
                    </div>
                </div>

                <div class="info">
        
                    <div class="infoset" v-for="contact in badgeData.badgeContactList" :key="contact.id" :value="contact.id">
                        <badgecontact :contact="contact" @save="saveinfoitem"/>
                    </div>    
                </div>
                <div class="school">
                    <div class="infoset">
                        <label class="infobig">{{badgeData.location.name}}</label>
                        <label class="infobig">{{badgeData.location.phoneNumber | formatPhone}}</label>
                        <label class="infosmall">{{badgeData.location.emailAddress}}</label>
                        <label class="infosmall">{{badgeData.location.addressLine1}}</label>
                        <label class="infosmall">{{badgeData.location.city}} {{badgeData.location.stateCode}}  {{badgeData.location.postalCode}}</label>
                        <label class="infosmall">{{badgeData.location.emailAddress}}</label>
                        <a class="infolink" :href="schoolwebsite" target="_blank">{{badgeData.location.website}}</a>
                    </div>
                </div>

            </div>

            <!-- TIME -->
            <div v-if="currentNav=='time'" class="timeDiv" v-hammer:swipe.left="swipeleft">
                <!-- IN -->
                <div v-if="badgeData.person.isIn" class="vertFlex">
                    <div class="horiztext">
                        <label class="studentname">You are </label>
                        <label class="studentname clockintext">Clocked IN</label>
                    </div>
                    <label class="clockinsubtext">{{badgeData.person.lastInOutRoleName}} at {{badgeData.person.lastInOutDateTime | formatDateTime}}</label>
                    <div>
                        <smallbutton toplabel="Add Event" @click="startaddevent()" buttonclass="button eventbackground" />
                    </div>
                    <div>
                        <smallbutton toplabel="Clock Out" @click="clockout()" buttonclass="button clockoutbackground" />
                    </div>
                    <div class="eventtextdiv" v-if="eventresponse!=''">
                        <label class="eventtext">{{eventresponse}}</label>
                    </div>
                    <div class="history">
                        <label class="studentname" >Activity For</label>
                        <input type="date" v-model="activityDate" class="activitydate" @change="GetTimeHistory()"/> 
                        <div class="historydiv">
                            <div v-for="timeitem in TimeItemList" :key="timeitem.id" :value="timeitem.id" :class="historyitemsClass(timeitem)" >

                                <label class="timetext" >{{timeitem.dateTime | formatTime}} </label>
                                <label class="timetext" >{{timeitem.name}} </label>
                                <smallbutton toplabel="+" :buttonclass="expandbuttonClass(timeitem)" @click="toggledetail(timeitem)" v-if="timeitem.details!=null"/>
                                <label class="timetext historydetail" v-if="timeitem.showDetails" v-html="timeitem.details"></label>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <!-- OUT -->
                <div v-if="!badgeData.person.isIn"  class="vertFlex">
                    <div class="horiztext">
                        <label class="studentname">You are </label>
                        <label class="studentname clockouttext">Clocked OUT</label>
                    </div>
                        
                    <label class="studentname">Clock In as</label>
                    <div v-for="role in badgeData.PersonRoleList" :key="role.id" :value="role.id"  >
                         <smallbutton :toplabel="role.name" @click="clockin(role.id)" buttonclass="button clockinbackground" />
                    </div>
                </div>
            </div>

            <!-- DOCUMENT -->
            <div v-if="currentNav=='documents'" class="documentDiv" >

                    <div class="history">
                        <label class="studentname" >Documents</label>
                        <div v-if="pleaseWait"><label class="pleasewaitdoc">Please wait...</label></div>
                        <div v-if="pleaseWaitSign"><label class="pleasewaitdoc">Please wait while we prepare your document...</label></div>
                        <div >
                            <div class="docDiv">
                                <div v-for="document in DocumentList" :key="document.id" :value="document.id" class="documentitems" >
                                    <img src="@/assets/check.png" class="docimg" v-if="document.fileName!=null"/>
                                    <div class="vertFlex col2" >
                                        <div class="horizFlex">
                                            <label :class="doctitleClass(document)" >{{document.typeName}}</label>
                                            <label class="required" v-if="document.required" >*</label>
                                        </div>
                                        <!-- <linkbutton :href="document.fileName" target="_blank" :toplabel="document.fileDisplayName"/> -->
                                        <a class="timetext" :href="document.fileName" target="_blank" v-if="document.canView">{{document.fileDisplayName }} </a>
                                        <a class="timetext" :href="document.fileName_Signature" target="_blank" v-if="showSignatureLink(document)">{{document.enteredName_Signature }}  Signature on  {{ document.signatureDate | formatDate}} </a>
                                    </div>
                                    
                                    <div class="vertFlex col3">
                                        <button class="docbutton" v-if="canGetDocument(document)" @click="openDocImage(document)">
                                            <img src="@/assets/file-document-edit-outline.png" class="docimg2"/>
                                        </button>
                                        <linkbutton :class="signlinkClass(document)"  toplabel="sign" @click="openSignModal(document.fileName, document.id,document.typeName)" v-if="okToSign(document) && useExternalSign==false"/>
                                        <linkbutton toplabel="Fill and Sign" @click="externalSign(document.id, document.externalSignatureTemplate)" v-if="okToSign(document) && useExternalSign"/>
                                    </div>      
                                </div>
                            </div>
                            
                            <label class="required"  >{{documentRequiredText}}</label>
                            <imagealldoc ref="imgdoc"   :allowFileSelect="allowFileSelect" :allowCapture="allowCapture" @saveimg="saveimg_document"  :showImage='showImagesInCapture' />
                            <signaturemodal ref="signdoc" @savesign="savesign_document"/>
                        </div>
                        
                    </div>
                    
            </div>

        </div>
        <div>
        <label class="responsemessage">{{responseMessage}}</label>
        </div>
        <div class="toggle" @click="toggleCard">
            <div class="horizFlex">
                <img alt="school logo" :src="badgeIcon_footer"  class="logo" >
                <div class="vertFlexLeft">
                    <label class="titleText">{{footLinkText}}</label>
                </div>
            </div>
        </div>

        <!-- drivers license -->
        <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <div >
                <div class="rightjustified">
                    <smallbutton @click="$refs.mymodal.closeModal()" toplabel='X' buttonclass="buttonclose" />
                </div>
                <label class="studentname">Driver's License</label>
                <div :class="licenseFrontClass" @click="startlicensefront()">
                    <img :src="imagePathLicenseFront" class="popuppic"   v-if="capturedLicenseFrontURL==null" />
                </div>
                <img alt="dl image"  :src="capturedLicenseFrontURL" class="popuppic" v-if="capturedLicenseFrontURL!=null"/> 

               <!-- GET PIC  -->
                <smallbutton toplabel="Update Front" @click="startlicensefront()" v-if="allowCaptureLicenseFront"/>
                <imageall ref="imgfront" captureText="Take Photo of Front"  :allowFileSelect="allowFileSelect" :allowFileSelectIfNoCapture='allowFileSelectIfNoCapture' @saveimg="saveimg_LicenseFront"  :showImage='showImagesInCapture' v-if="allowCaptureLicenseFront"/>


                <div :class="licenseBackClass" @click="startlicenseback()">
                    <img :src="imagePathLicenseBack" class="popuppic"   v-if="capturedLicenseBackURL==null" />
                </div>
                <img alt="dl image"  :src="capturedLicenseBackURL" class="popuppic" v-if="capturedLicenseBackURL!=null"/> 
 
                <!-- GET PIC  -->
                <smallbutton toplabel="Update Back" @click="startlicenseback()" v-if="allowCaptureLicenseBack"/>
                <imageall ref="imgback" captureText="Take Photo of Back"  :allowFileSelect="allowFileSelect" :allowFileSelectIfNoCapture='allowFileSelectIfNoCapture' @saveimg="saveimg_LicenseBack"  :showImage='showImagesInCapture' v-if="allowCaptureLicenseBack"/>

                <!-- <smallbutton @click="$refs.mymodal.closeModal()" toplabel='Close' buttonclass="button buttonhighlight" /> -->
            </div>
        </template>
      </actionmodal>
      <!-- vaccine -->
      <actionmodal ref="vaccine"> 
        <template v-slot:body>
            <div >
                <div class="rightjustified">
                    <smallbutton @click="$refs.vaccine.closeModal()" toplabel='X' buttonclass="buttonclose" />
                </div>
                
                <label class="studentname">Vaccine</label>
                <div :class="vaccineCardClass" @click="startvaccine()">
                    <img :src="imagePathVaccineCard" class="popuppic"   v-if="capturedVaccineCardURL==null" />
                </div>
                <img alt="dl image"  :src="capturedVaccineCardURL" class="popuppic" v-if="capturedVaccineCardURL!=null"/> 
                
                <!-- GET PIC  -->
                <smallbutton toplabel="Update Card or Test" @click="startvaccine()" v-if="allowCaptureVaccineCard"/>
                <imageall ref="imgvac" captureText="Take Photo of Card or Test"  :allowFileSelect="allowFileSelect" :allowFileSelectIfNoCapture='allowFileSelectIfNoCapture' @saveimg="saveimg_Vaccine"  :showImage='showImagesInCapture' v-if="allowCaptureVaccineCard"/>

            
                <!-- <smallbutton @click="$refs.vaccine.closeModal()" toplabel='Close' buttonclass="button buttonhighlight" /> -->
            </div>
        </template>
      </actionmodal>

        <!-- add event -->
      <actionmodal ref="eventmodal"> 
        <template v-slot:body>
            <div >
                <label class="studentname">Add Event</label>
                <div class="eventbuttons">
                  
                    <smallbutton v-for="event in badgeData.PersonEventList" :key="event.id" :value="event.id" 
                        :toplabel="event.name" @click="addevent(event.id,event.name)" buttonclass="eventbutton" />
                    
                </div>
                <smallbutton @click="$refs.eventmodal.closeModal()" toplabel='Cancel' buttonclass="button buttonprimary" />
            </div>
        </template>
      </actionmodal>
        

         <!-- sign -->
      <actionmodal ref="signmodal"> 
        <template v-slot:body>
            <div >
                <label class="studentname">Signature</label>
                <br>
                <div class="signaturebox"/>
                <div>
                    <smallbutton @click="$refs.signmodal.closeModal()" toplabel='Save' buttonclass="button buttonprimary" />
                    <smallbutton @click="$refs.signmodal.closeModal()" toplabel='Cancel' buttonclass="button buttonprimary" />
                </div>
            </div>
        </template>
      </actionmodal>
  </div>
    <GlobalEvents v-if="listenersConnected" target="window" @focus="GetDocuments"/>
</div>
  
</template>

<script>
import VueBarcode from 'vue-barcode'
import QrcodeVue from 'qrcode.vue'
import {mapFields} from 'vuex-map-fields'
import style from '../style.js'
import datamethods from '../datamethods'
import loginuser from './login/loginuser.vue'
import format from '../format.js'
import badgeburgermenu from './badgemenu.vue'
import badgecontact from './badgecontact.vue'
import actionmodal from './controls/actionmodal.vue'
import smallbutton from './controls/button.vue'
import imagecapture from './controls/ImageCapture.vue'
import splash from './splash.vue'
import imageall from './controls/ImageAll.vue'
import linkbutton from './controls/linkbutton.vue'
import imagealldoc from './controls/ImageAllDoc.vue'
import signaturemodal from './controls/signaturemodal.vue'
export default {
    components:{
        'barcode':VueBarcode,
        loginuser,
        badgeburgermenu,
        badgecontact,
        actionmodal,
        smallbutton,
        QrcodeVue,
        imagecapture,
        splash,
        imageall,
        linkbutton,
        imagealldoc,
        signaturemodal
    
    },
    mixins:[style, datamethods, format],
    
    data(){
        return{
            listenersConnected:false,

            pleaseWait:false,
            pleaseWaitSign:false,
            logoOverride:'',

            showImagesInCapture:false,
            allowFileSelectIfNoCapture:true,
            
            /* config:{

                // "bottomleft", "bottomright", "topleft", "topright", "center", "fill"
                mode: "bottomright",
                textBaseline: "middle",
                font: "15px Arial",
                fillStyle: "white",
                content: "@vuescript.com",
                rotate: 30
                
            },
            pullconfig:{
                pullDownHeight:50,
                loadingLabel:'refresh',
                startLabel:'refresh',
                errorLabel:'refresh',
                readyLabel:'refresh'
            }, */

            //url
            BadgeGlobalKey:'',
            EmailAddress:'',
            LocationGlobalKey:'',
            PersonGlobalKey:'',

            //time
            activityDate:null,
            TimeItemList:[],

            //doc
            DocumentList:[],

            //config
            institutionName:window.INSTITUTION_NAME,
            schoolLogoFilePath:'./../' + window.SCHOOL_LOGO,
            //frontLinkText:window.FRONT_LINK_TEXT,
            backLinkText:window.BACK_LINK_TEXT,

            //flags
            responseMessage:'',

            //photos
            capturedPerson:null,
            capturedPersonURL:null,
            hasImagePerson:true,

            capturedLicenseFront:null,
            capturedLicenseFrontURL:null,
            hasLicenseFront:true,

            capturedLicenseBack:null,
            capturedLicenseBackURL:null,
            hasLicenseBack:true,

            capturedVaccineCard:null,
            capturedVaccineCardURL:null,
            hasVaccineCard:true,
            eventresponse:'',

            showDocsAfterBadge:false
        }
    },
    computed:{

        ...mapFields([
            'loggedIn',
            'currentNav',
            'badgeData',
            'loggedInUser',
            'imageBasePath',
            'BadgeDesign',
            'isReady',
            'username',
            'oldpassword',
            'showHeader',
            'token'

        ]),
        useExternalSign(){
        
         if(window.USE_EXTERNAL_SIGNING==undefined){
                return false
            }else{return window.USE_EXTERNAL_SIGNING}
       },
       menuOpenAtStart(){
        
         if(window.MENU_OPEN_AT_START==undefined){
                return false
            }else{return window.MENU_OPEN_AT_START}
       },
       documentsOpenAtStart(){
        
         if(window.DOCUMENTS_OPEN_AT_START==undefined){
                return false
            }else{return window.DOCUMENTS_OPEN_AT_START}
       },
        showClass(){
            var show = window.SHOW_CLASS
            if(show==undefined){
                show=true
            }
            return show
        },
        outerDivClass(){
            if(this.currentNav=='front'){return "outerDivForFront"}
            else{return "outerDiv"}
        },
        
        allowFileSelect(){
            var allow = window.ALWAYS_ALLOW_FILE_SELECT
            if(allow==undefined){
                allow=false
            }
            return allow
        },
        schoolwebsite(){
            if(this.badgeData==undefined){return ""}
            var site = this.badgeData.location.website;
            if(site==undefined){return ""}
            if(!site.includes('http')){
                site = 'https://' + site
            }
            return site
        },
        badgeIcon(){
            if(this.BadgeDesign==undefined){return './../' + window.SCHOOL_LOGO}
            return this.imageBasePath + this.BadgeDesign.badgeIcon
        },
        badgeIcon_footer(){
            if(this.BadgeDesign==undefined){return './../' + window.SCHOOL_LOGO}
            return this.imageBasePath + this.BadgeDesign.badgeIcon_Footer
        },
        barcodeType(){
            if(this.BadgeDesign==undefined){return "CODE39"}
            if(this.BadgeDesign.barcodeType==null){return "CODE39"}
            return this.BadgeDesign.barcodeType
        },
        
        titleText(){
            if(!this.loggedIn){
                
                return this.institutionName;
            }else if(this.currentNav=='front' || this.currentNav=='time'){
                if(this.badgeData!=undefined){
                    if(this.badgeData.person!=undefined){
                        return this.badgeData.person.badgeTitle;
                    //return this.badgeData.location.name;
                    }
                    
                }
            }else if(this.currentNav=='back'){
                if(this.BadgeDesign==undefined){return ""}
                return this.BadgeDesign.informationPageTitle;
                //return this.frontLinkText;
            }
        },
        footLinkText(){
            if(!this.loggedIn){
                return "";
            }else if(this.currentNav=='front'){
                if(this.BadgeDesign==undefined){return " >>>"}
                return this.BadgeDesign.informationPageTitle + ' >>>';
                //return this.frontLinkText + ' >>>';
            }else if(this.currentNav=='back'){
                return '<<< ' + this.backLinkText;
            }
        },
        hasBadge(){
             if(this.badgeData==undefined){return false}
             return true
        },
        hasBadgePerson(){
            if(!this.hasBadge){return false}
            if(this.badgeData.person==undefined){return false}
            return true
        },
        hasAlerts(){
            if(!this.hasBadge){return false}
            if(this.badgeData.BadgeAlertList==undefined){return false}
            return this.badgeData.BadgeAlertList.length>0
        },
        statusdivClass(){
            if(!this.hasBadgePerson){return}
            if(!this.badgeData.person.status_IsAvailable){return "status statusdivunavailable"}
            else if(this.badgeData.person.status_ShowAsPositive){return "status statusdivpositive"}
            else if(this.badgeData.person.status_ShowAsNegative){return "status statusdivnegative"}
            else {return "status statusdivneutral"}
        },
        showStatus(){
            if(!this.hasBadgePerson){return false}
            return this.badgeData.person.status_ShowOnBadge
        },
        showInactive(){
            console.log(this.badgeData.person.firstName + ' ' + this.badgeData.person.isActive)
            if(this.badgeData.person.firstName==undefined){return false}
            return this.badgeData.person.isActive==false
        },
        licenseClass(){
            if(this.BadgeDesign==undefined){return "logo hidebuttakespace"}
            if(this.BadgeDesign.showLicenseIcon){
                return "logo"
            }else{return "logo hidebuttakespace"}
        },
        vaccineClass(){
            if(this.BadgeDesign==undefined){return "logo hidebuttakespace"}
            if(this.BadgeDesign.showVaccineCardIcon){
                return "logo"
            }else{return "logo hidebuttakespace"}
        },
        
        // person image
        haveSelfie(){
            return this.imagePathPerson!=null || this.capturedPersonURL!=null
        },
        imagePathPerson(){
            if(!this.hasBadge){return}
            if(this.badgeData.person==undefined){return}
            var path = this.badgeData.person.personImageFileName;
            if(path==''){return}
            return this.imageBasePath + path;
        },

        allowCapturePerson(){
            if(!this.hasBadge){return false}
            if(this.BadgeDesign==undefined){return false}
            var allowCapture = this.BadgeDesign.capturePersonImage
            var allowReplace = this.BadgeDesign.allowReplaceImage
            if(allowReplace && allowCapture){return true}
            if(allowCapture && !this.hasImagePerson){return true}
            if(this.imagePathPerson.includes('WM_')){return true}
            return false
        },
        personImageClass(){
            if(!this.hasBadge){return ""}
            if(this.badgeData.person.personImageValidated){return ""}
             if(this.hasPersonImage==false){return ""}
            else{return "watermarked"}
        },

        // dl front image
        imagePathLicenseFront(){
            if(!this.hasBadge){return}
            if(this.badgeData.person==undefined){return}
            var path = this.badgeData.person.licenseFrontFileName;
            if(path==''){return}
            return this.imageBasePath + path;
        },

        allowCaptureLicenseFront(){
            var allowCapture = this.BadgeDesign.captureLicenseFront
            var allowReplace = this.BadgeDesign.allowReplaceImage
            if(allowReplace && allowCapture){return true}
            if(allowCapture && !this.hasLicenseFront){return true}
            if(this.imagePathLicenseFront.includes('WM_')){return true}
            return false
        },
        licenseFrontClass(){
            if(!this.hasBadge){return ""}
            if(this.badgeData.person.licenseFrontValidated){return ""}
             if(this.hasLicenseFront==false){return ""}
            else{return "watermarked"}
        },

         // dl back image
        imagePathLicenseBack(){
            if(!this.hasBadge){return}
            if(this.badgeData.person==undefined){return}
            var path = this.badgeData.person.licenseBackFileName;
            if(path==''){return}
            return this.imageBasePath + path;
        },
        allowCaptureLicenseBack(){
            var allowCapture = this.BadgeDesign.captureLicenseBack
            var allowReplace = this.BadgeDesign.allowReplaceImage
            if(allowReplace && allowCapture){return true}
            if(allowCapture && !this.hasLicenseBack){return true}
            if(this.imagePathLicenseBack.includes('WM_')){return true}
            return false
        },
        licenseBackClass(){
            if(!this.hasBadge){return ""}
            if(this.badgeData.person.licenseBackValidated){return ""}
            if(this.hasLicenseBack==false){return ""}
            else{return "watermarked"}
        },

        // vaccine image
        imagePathVaccineCard(){
            if(!this.hasBadge){return}
            if(this.badgeData.person==undefined){return}
            var path = this.badgeData.person.vaccineCardFileName;
            if(path==''){return}
            return this.imageBasePath + path;
        },

        allowCaptureVaccineCard(){
            var allowCapture = this.BadgeDesign.captureVaccineCard
            var allowReplace = this.BadgeDesign.allowReplaceImage
            if(allowReplace && allowCapture){return true}
            if(allowCapture && !this.hasVaccineCard){return true}
            if(this.imagePathVaccineCard.includes('WM_')){return true}
            return false
        },
        vaccineCardClass(){
            if(!this.hasBadge){return ""}
            if(this.badgeData.person.vaccineCardValidated){return ""}
            if(this.hasVaccineCard==false){return ""}
            else{return "watermarked"}
        },
        documentRequiredText(){
            if(window.REQUIRE_DOC_TEXT_OVERRIDE!=undefined){
                if(window.REQUIRE_DOC_TEXT_OVERRIDE!=''){
                    return window.REQUIRE_DOC_TEXT_OVERRIDE
                }
            }
            return '* required'
        }
    },
    methods:{
        okToSign(document){
            return document.fileDisplayName!=null && document.canSign && document.fileName_Signature==null
        },
        showSignatureLink(document){
            return document.fileName_Signature!=null && document.fileName_Signature!="externalsignature"
        },
        async externalSign(docid,  template){
            var windowReference = window.open('about:blank','_blank');
            var html = "<!DOCTYPE html><html lang=''><head></head><body><div style='display:flex;flex-direction:column;text-align:center;font-size:45px;'><p style='margin-top:50px;'>Please wait while we prepare your document...<p></div></body></html>"
            windowReference.document.write(html)
            this.pleaseWaitSign=true
            let req = {
                personName:this.loggedInUser.Name,
                personEmail:this.loggedInUser.EmailAddress,
                documentId:docid,
                externalSignTemplateID:template
            }
            var response = await this.callAPI(req,'startsigndocument')
            //handle response
            if(response.Successful){
                this.pleaseWaitSign=false
                this.listenersConnected=true
                
                this.$nextTick(()=>{
                    windowReference.location=response.url
                })
                
                //windowReference.close()
            }else{
                this.pleaseWaitSign=false
                this.responseMessage = response.Message
                windowReference.close()
            }
        },


        signlinkClass(doc){
            if(doc.requireSignature){return "signlinkrequired"}
            else{return "signlink"}
        },
        historyitemsClass(item){
            var c="historyitems"
            switch (item.itemType) {
                case 'IN':
                    c=c + " historyIn"
                    break;
                case 'OUT':
                    c=c + " historyOut"
                    break;
                case 'EVENT':
                    c=c + " historyEvent"
                    break;
                default:
                    break;
            }
            return c
            
        },
        expandbuttonClass(item){
            var c="expandbutton"
            switch (item.itemType) {
                case 'IN':
                    c=c + " historyIn"
                    break;
                case 'OUT':
                    c=c + " historyOut"
                    break;
                case 'EVENT':
                    c=c + " historyEvent"
                    break;
                default:
                    break;
            }
            return c
        },
        async saveinfoitem(id, text){
            let req = {
                id:this.loggedInUser.badgeId,
                BadgeInformation:{
                    id:id,
                    infoText:text
                }
                
            }

            var response = await this.callAPI(req,'saveinfoitem')
            //handle response
            if(response.Successful){
                this.getBadgeDetail();  
            
            }else{
                this.responseMessage = response.Message
            }
        },
        menuRefresh(){
            switch (this.currentNav) {
                case 'documents':
                    this.GetDocuments()
                    break;
                case 'front', 'back':
                    this.getBadgeDetail()
                    break;
                case 'time':
                    this.getBadgeDetail()
                    this.GetTimeHistory()
                    break;
                default:
                    break;
            }
            

        },
        menuhome(){
            this.getBadgeDetail()
            this.currentNav='front'
        },
        showdown(alert){
            return this.alertislong(alert) && !alert.expanded
        },
        showup(alert){
            return this.alertislong(alert) && alert.expanded
        },
        alertislong(alert){
            if(alert.alertLine2!=null){
                return (alert.alertLine2.length>40)
            }else{return false}
            
        },
        togglebuttontext(alert){
            if(alert.expanded){return "&#9650"}
            else{return "&#9660"}
        },
        togglealert(alert){
            alert.expanded=!alert.expanded
        },
        alertClass(alert){
            var c ="alertdiv" 
            if(alert.wasRead){c=c+" alertdivread"}
            else{c=c+ " alertdivunread"}
            if(alert.expanded){
                c=c+" alerttall"
            }else{c=c+" alertshort"}
            return c
        },
        async dismissalert(alert){
            let req = {
                badgeId:this.loggedInUser.badgeId,
                badgeAlertId:alert.id
            }

            var response = await this.callAPI(req,'dismissalert')
            //handle response
            if(response.Successful){
                this.getBadgeDetail();  
            }else{
                this.responseMessage = response.Message
            }
        },
        afterLogin(){
            
            this.getBadgeDetail();
            this.currentNav='front'

            if(this.documentsOpenAtStart){this.showDocsAfterBadge=true}
            if(this.menuOpenAtStart){this.$refs.topmenu.toggleMenu();}
        },
       
        
        logout(){
            this.loggedIn=false;
            localStorage.token="";
            console.log('out')
        },
        missingVaccineCard(){
            this.hasVaccineCard=false
            console.log('missingvc')
        },
        missingLicenseFront(){
            this.hasLicenseFront=false

        },
        missingLicenseBack(){
            this.hasLicenseBack=false
        },
        
        onRefresh(){
            console.log('refresh!')
        },
        
        //IMAGES
        startselfie(){
            this.$refs.imgselfie.openModal()
        },
        startlicenseback(){
            this.$refs.imgback.openModal()
        },
        startlicensefront(){
            console.log('front')
            this.$refs.imgfront.openModal()
        },
        startvaccine(){
            this.$refs.imgvac.openModal()
        },
        saveimg_selfie(file,url){
            this.capturedPerson=file
            this.capturedPersonURL=url
            this.storeImage(this.badgeData.person.personImageFileName, file, file.name,"person")
        },
        saveimg_LicenseFront(file,url){
            this.capturedLicenseFront=file
            this.capturedLicenseFrontURL=url
            this.storeImage(this.badgeData.person.licenseFrontFileName, file, file.name,"front")
        },
       
        saveimg_LicenseBack(file,url){
            this.capturedLicenseBack=file
            this.capturedLicenseBackURL=url
            this.storeImage(this.badgeData.person.licenseBackFileName, file, file.name,"back")
        },
      
        saveimg_Vaccine(file,url){
            this.capturedVaccineCard=file
            this.capturedVaccineCardURL=url
            this.storeImage(this.badgeData.person.vaccineCardFileName, file, file.name,"vaccine")
        },
        async storeImage(filename,file, name,type){
             var bId=this.badgeData.person.badgeId
            var postParams = new FormData();

            postParams.append(filename,file,name);
            
            postParams.append('badgeId',bId);
            postParams.append('type',type);

            var response = await this.callAPI(postParams,'saveimage')
            //handle response
            if(response.Successful){
                this.getBadgeDetail();  
            }else{
                this.responseMessage = response.Message
            }
             
        },
        
        clearTempImages(){
            this.capturedPerson=null
            this.capturedLicenseBack=null 
            this.capturedLicenseBack=null
            this.capturedVaccineCard=null
            this.capturedPersonURL=null
            this.capturedLicenseBackURL=null 
            this.capturedLicenseBackURL=null
            this.capturedVaccineCardURL=null
        },
        async getImageBasePath(){
            //call api
            var response = await this.callAPI(null,'imagepath')
            //handle response
            if(response.Successful){
                this.imageBasePath = response.StringResponse
            }else{
                this.responseMessage = response.Message
            }
        },
        openDoc(docname){
           window.open( this.imageBasePath + docname,"_blank");
           return false;
        },
        getDocURL(docname){
            return this.imageBasePath + docname;
        },
        toggleCard(){
            console.log('toggle' + this.currentNav)
            if(this.currentNav=='front'){this.currentNav='back'}
            else{this.currentNav='front'}
        },
        show(nav){
            console.log('show ' + nav)
            this.currentNav=nav
        },
        swiperight(){
            console.log('right')
            if(this.currentNav=='front'){
                if(this.BadgeDesign.showTimeclock){
                    this.currentNav='time'
                }
                
            }else if(this.currentNav=='back'){
                this.currentNav='front'
            }
        },
        swipeleft(){
            console.log('left')
            if(this.currentNav=='front'){
                this.currentNav='back'
            }else if(this.currentNav=='time'){
                this.currentNav='front'
            }
        },
        showback(){
            this.show('back')
        },
        showtime(){
            this.show('time')
        },
        showdocuments(){
            this.GetDocuments()
            this.show('documents')
        },
        payaccount(){
            var url = window.ECOMMERCE_URL_ACCOUNT
            url = url + "&email=" + this.username + "&token=" + this.token
            window.open(url,'_blank')
        },
        addfunds(){
            var url = window.FUNDING_URL
            url = url + "?token=" + this.token
            window.open(url,'_blank')
        },
        showfront(){
            this.show('front')
        },
        async getBadgeDetail(){
            this.responseMessage=''
            //reset
            this.hasPersonImage=true
            this.hasLicenseFront=true
            this.hasLicenseBack=true
            this.hasVaccineCard=true

            console.log('getbadge')
            //setup request
            //if we have a style id, provide it
            var styleId=-1
            if(this.badgeData.person!=undefined){
                console.log('have person')
                styleId=this.badgeData.person.badgeDesignID
            }
            let req = {
                personId:this.loggedInUser.id,
                badgeId:this.loggedInUser.badgeId,
                badgeDesignId_previous:styleId
            }
            //call api
            console.log('about to all for badge')
            var response = await this.callAPI(req,'badge')
            //handle response
            if(response.Successful){
                console.log('got badge')
                this.badgeData=response
                //this.currentNav='front' 
                this.clearTempImages()
                this.updateStyle()
                if(this.BadgeDesign.showTimeclock){
                    this.GetTimeHistory()
                }
                if(this.showDocsAfterBadge){
                    this.showDocsAfterBadge=false //single use
                    this.showdocuments();
                }
            }else{
                this.responseMessage = response.Message
                console.log('unauth')
                this.logout();
            }
   
        },
        updateStyle(){
            //IF needed
            if(this.badgeData.person.refreshBadgeDesign){
                //set colors
                console.log(this.badgeData.BadgeDesign)
                this.BadgeDesign=this.badgeData.BadgeDesign

                if(this.BadgeDesign==undefined){console.log('broken'); return;}
                this.overrideColors(this.BadgeDesign.titleTextColor,this.BadgeDesign.mainTextColor,
                    this.BadgeDesign.menuTextColor,this.BadgeDesign.titleBackgroundColor,
                    this.BadgeDesign.mainBackgroundColor)

                this.setbackgroundimage()
               // this.setBackgroundImage(this.imageBasePath + this.BadgeDesign.badgeBackground)

               // console.log("back img " + this.imageBasePath + this.BadgeDesign.badgeBackground)
            }
        },
        setbackgroundimage(){
            
    
            if(this.BadgeDesign.badgeBackground!=null){
                console.log('setbkgrnd ' + this.imageBasePath + this.BadgeDesign.badgeBackground)
                 document.body.style.setProperty('--image','url('+ this.imageBasePath + this.BadgeDesign.badgeBackground +')');
            }
           
            
        },

        //timeclock
        toggledetail(timeitem){
            console.log('toggle ')
            timeitem.showDetails=!timeitem.showDetails
        },
        async clockout(){
            let req = {
                badgeId:this.loggedInUser.badgeId,
                personId:this.loggedInUser.id,
                locationId:this.badgeData.location.id
            }

            var response = await this.callAPI(req,'clockout')
            //handle response
            if(response.Successful){
                this.eventresponse=''
                this.getBadgeDetail();  
            }else{
                this.responseMessage = response.Message
            }
        },
        async clockin(roleid){
            let req = {
                badgeId:this.loggedInUser.badgeId,
                personId:this.loggedInUser.id,
                locationId:this.badgeData.location.id,
                personRoleId:roleid
            }

            var response = await this.callAPI(req,'clockin')
            //handle response
            if(response.Successful){
                this.eventresponse=''
                this.getBadgeDetail();  
            }else{
                this.responseMessage = response.Message
            }
        },
        startaddevent(){
            this.eventresponse=''
            this.$refs.eventmodal.openModal();
        },
        async addevent(eventid, eventname){
            let req = {
                badgeId:this.loggedInUser.badgeId,
                personId:this.loggedInUser.id,
                locationId:this.badgeData.location.id,
                eventId:eventid
            }

            var response = await this.callAPI(req,'addevent')
            //handle response
            if(response.Successful){
                this.eventresponse = "You've added event " + eventname + " at " + this.$options.filters.formatDateTimeForToday()
                this.$refs.eventmodal.closeModal()
                this.getBadgeDetail();  
            }else{
                this.responseMessage = response.Message
            }
        },
        async GetTimeHistory(){
            console.log('timehistory')
            let req = {
                badgeId:this.loggedInUser.badgeId,
                startDate:this.activityDate
            }

            var response = await this.callAPI(req,'timehistory')
            //handle response
            if(response.Successful){
                this.TimeItemList=response.TimeclockHistoryItemList
            }else{
                this.responseMessage = response.Message
            }
        },

        //documents
        canGetDocument(document){
            return (document.canCapture || document.canUpload) && document.fileName==null
        },
        doctitleClass(doc){
            if(doc.fileName_Signature==null){
                return "doctitle"
            }else{
                return "doctitlesigned"
            }
        },
        async GetDocuments(){
            if(this.listenersConnected){this.listenersConnected=false}
            this.pleaseWait=true
            console.log('docs')
            var basepath = this.imageBasePath 
            console.log('base ' + basepath)
            let req = {
                personId:this.loggedInUser.id,
                locationId:this.badgeData.location.id
            }

            //DECIDE if we are doing docs by loc or not. default is no
            var docbyloc = false;
            if(window.DOCUMENTS_BY_LOCATION!=undefined){
                docbyloc=window.DOCUMENTS_BY_LOCATION;
            }

            var response;
            if(docbyloc){
                response= await this.callAPI(req,'documents_bylocation')
            } else{
                response= await this.callAPI(req,'documents')
            }

            //handle response
            if(response.Successful){
                this.DocumentList=response.DocumentList
                this.DocumentList.forEach(myFunction2)
                
                function myFunction2(item, index, arr) {
                    let acct=arr[index]
                    if(acct.fileName!=null){
                        let newpath = basepath + acct.fileName
                        console.log('b ' + acct + ' ' + newpath)
                        acct.fileName = newpath
                    }
                    if(acct.fileName_Signature!=null && acct.fileName_Signature!="externalsignature"){
                        let newpath2 = basepath + acct.fileName_Signature
                        acct.fileName_Signature = newpath2
                    }
                } 
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false
        },
        
        saveimg_document(file,url,typeId){
            this.storeDocument("doc", file, file.name,typeId)
        },
        async savesign_document(file,url,docname,docid, enteredName){
            console.log('savesign')
             var pId=this.loggedInUser.id
            var postParams = new FormData();

            postParams.append("sign",file,name);
            postParams.append('docname',docname);
            postParams.append('docid',docid);
            postParams.append('enteredname',enteredName);

            var response = await this.callAPI(postParams,'savesignature')
            //handle response
            if(response.Successful){
                this.GetDocuments();  
            }else{
                this.responseMessage = response.Message
            }
        },
        async storeDocument(filename,file, name,typeId){
             var pId=this.loggedInUser.id
            var postParams = new FormData();

            postParams.append(filename,file,name);
            
            postParams.append('personId',pId);
            postParams.append('typeId',typeId);

            var response = await this.callAPI(postParams,'savedocument')
            //handle response
            if(response.Successful){
                this.GetDocuments();  
            }else{
                this.responseMessage = response.Message
            }
             
        },
        openDocImage(doc){
            console.log(doc.typeId)
            this.$refs.imgdoc.referenceID=doc.typeId
            this.$refs.imgdoc.allowFileSelect=doc.allowUpload
            this.$refs.imgdoc.allowCapture=doc.allowCapture
            this.$refs.imgdoc.openModal()
        },
        openSignModal(docname,docid,doctypename){
            /* this.$refs.signdoc.referenceDoc=docname
            this.$refs.signdoc.documentId=docid
            this.$refs.signdoc.documentName=doctypename */
            this.$refs.signdoc.setValues(docname,docid,doctypename)
            this.$refs.signdoc.openModal()
        },

        //color
        async setupInitialColors(){
            

            //call api
            var response = await this.callAPI(null,'badgedesigndefault')
            //handle response
            if(response.Successful){
                console.log('initial colors')
                this.badgeData=response
                this.updateStyle()

                if(this.logoOverride!=''){
                    this.BadgeDesign.badgeIcon=this.logoOverride
                    this.BadgeDesign.badgeIcon_Footer=this.logoOverride
                }
    
            }else{
                this.responseMessage = response.Message
            }
        },
        //this is if we had a token passed in!
        async post_login(token){
            //add to api
            this.AddTokenToAPI(token)
            this.token = token
            //store?
            if(this.storeLogin){
                localStorage.token=token;
            }

            //no username/pwd
            this.responseMessage='';
            //validate
            var response = await this.callAPI(null,'badgelogin')
            //handle response
            if(response.Successful){
                console.log(response)
                this.loggedInUser= response.Person
                this.loggedIn=true
                this.didFailAuth=false
                this.afterLogin()
                //this.$emit('loggedin')
                //this.AddUserToAPI(this.loggedInUser)
               this.setready()
            }else{
               this.loggedIn=false
                //don't show the message on the mounted login
                if(this.isMountedLogin){
                    this.isMountedLogin=false
                }else{
                    this.responseMessage = response.Message
                }
                this.setready()
            }
        },
        setready(){
            this.isReady=true
        },
        receiveMessage(event){
        
            var msg =  JSON.parse(event.data)
            var name = msg.to
            if(name !=undefined){
            
                console.log(name)
                console.log('MOBILEID msg' + event.data)

                switch (msg.action) {
                    case 'refresh':
                        this.badgeData.person.badgeDesignID = -1//force refresh of badge design
                        this.menuRefresh()
                        break;
                    default:
                        break;
                }
            
            }
        }

    },
    mounted(){
        window.addEventListener('message', this.receiveMessage)

        var goAhead = true;
        let urlParams = new URLSearchParams(window.location.search)
        let tmp = urlParams.get('BadgeGlobalKey')
        if(tmp!=null){ this.BadgeGlobalKey = tmp;}

        tmp = urlParams.get('LocationGlobalKey')
        if(tmp!=null){ this.LocationGlobalKey = tmp;}

        tmp = urlParams.get('PersonGlobalKey')
        if(tmp!=null){ this.PersonGlobalKey = tmp;}

        tmp = urlParams.get('EmailAddress')
        if(tmp!=null){ 
            this.EmailAddress = tmp;
            this.username=tmp;
            }
        
        tmp = urlParams.get('email')
        if(tmp!=null){this.username = tmp; }

        tmp = urlParams.get('temp')
        if(tmp!=null){
          
            console.log('in ch pass ')
            this.oldpassword = tmp;
            goAhead=false
            this.$nextTick(()=>{
            //this.changepass()
            this.$refs.login.changepassword();
            
            });
          
        }


        tmp = urlParams.get('logo')
        if(tmp!=null){ this.logoOverride = tmp;}

        //token
        tmp = urlParams.get('googletoken')
        if(tmp!=null){ 
            this.token = tmp;
            console.log('gottoken')
            }

        window.onbeforeunload= function(event){
            event.preventDefault()
        }

        //init date for timehistory
        this.activityDate=this.$options.filters.formatDateForInputToday()
    

        this.getImageBasePath();
        this.setupInitialColors()

        tmp = urlParams.get('header')
        if(tmp!=null){this.showHeader=tmp=='true'; }

        //determine if token sent over (SSO)
        tmp = urlParams.get('token')
        if(tmp!=null){
            console.log('sso!')
            this.token=tmp
            this.$refs.login.post_login(tmp)
         }else{
            if(this.token!=null){
                //send it
                this.post_login(this.token)
            }else{
                //regular login rules
                this.$refs.login.prelogin()
            }
         }


        
        
    
    }
}
</script>

<style lang="scss">
@import "@/assets/stylebase.scss";

@media screen and (min-width: 812px) {
    .imagediv{
        border: 2px solid var(--primary-color);
        background-color:white;
        display:flex;
        flex-direction: column;
        align-items: center;
        width:40%;
        margin:auto;
    }
}
/* phone */
@media screen and (max-width: 812px) {
   .imagediv{
        border: 2px solid var(--primary-color);
        background-color:white;
        display:flex;
        flex-direction: column;
        align-items: center;
        width:95%;
        margin:auto;
    }
  
}


.testdiv{
     background-image:var(--image);
    //background-image:url('https://mobileidstorage.blob.core.windows.net/64c0c9ea-2b3d-497a-816b-6cc9c608be12/LicenseFront.jpg');
     background-size:cover;
     background-position:center;
     background-repeat:no-repeat;
    //background-image:url('../assets/LicenseFront.png');
    height:100px;
    width:200px;
}
body{
  height:95vh; 
  width:100vw;
  padding:0px;
  margin:0px;
  font-size:large;
  
}
.outerDiv{
    margin:0px;
    padding:0px;
    height:95vh;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    color:var(--main-text-color);
    background-color:var(--main-background-color);
    
    /* background-image:var(--image);
    background-size:cover;
     background-position:center;
     background-repeat:no-repeat; */
}
.outerDivForFront{
    margin:0px;
    padding:0px;
    height:95vh;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    color:var(--main-text-color);
    background-color:var(--main-background-color);
    background-image:var(--image);
    background-size:cover;
     background-position:center;
     background-repeat:no-repeat;
}
.frontDiv{
    touch-action: pan-y !important;
    //height:100%;
   // background-image:var(--image);
   // background-size:cover;
    // background-position:center;
    // background-repeat:no-repeat;
    /* display:grid;
    grid-template-columns:auto;
    grid-template-rows:15% 42% 8% 20% 12%; */
}
.backDiv{
    touch-action: pan-y !important;
   // height:100%;
    /* display:grid;
    grid-template-columns:auto;
    grid-template-rows:12% 15% 46% 15% 12%; */
}

.titleText{
    font-weight: bold;
    margin-left:10px;
    color:var(--title-text-color)
}

/* HEADER */
.header{
    background-color:var(--title-background-color);
    
    padding:10px;
}
.logo{
    height:40px;
}
.logolabel{
    width:40px;
}
.hidebuttakespace{
    visibility: hidden;
}

/* ALERT */
.alert{
    padding:10px;
}
.alertdivunread{
    opacity:1;
}
.alertdiv{
    padding:5px;
    background-color: var(--alert-color);
    margin:2px;
    display:grid;
    grid-template-columns:8fr 1fr;
    grid-template-rows:auto auto;
    grid-template-areas: 
        "line1 dismiss"
        "line2 toggle";
}
.alertline1{
    grid-area:line1;
}
.alertline2{
    grid-area:line2;
}
.alertdismissbutton{
    grid-area:dismiss;
}
.alerttogglebutton{
    grid-area:toggle;
}
.alertdivread{
    opacity:.6;
}
.alerttall{
    height:auto;
}
.alertshort{
    height:40px;
}
.alerttext{
    color:var(--light-text-color);
    text-align:left;
}
.alerttextbold{
    color:var(--light-text-color);
    font-weight: bold;
    text-align:left;
}
.dismissbutton{
    border:none;
    background-color:transparent;
    color:white;
}
.tbuttondown{
    border:none;
    background-color:transparent;
    color:white;
    margin-bottom:20px;
}
.tbuttonup{
    border:none;
    background-color:transparent;
    color:white;
    margin-top:20px;
}
/* STUDENT */
.studentimage{
    min-height:140px;
    max-height:180px;
    margin:5px;
    position:relative;
    cursor:pointer;
}
.studentinfo{
    width:100%;
    text-align: center;
}
.studentname{
    font-size:x-large;
    font-weight:bolder;
    margin:5px 5px 5px 0px;
}

.studentclass{
    margin:5px;
}

/* STATUS */
.status{
    padding:10px 10px 0px 10px;
}
.statustext{
    font-weight: bold;
    font-size:x-large;
    color:var(--light-text-color);
}

.statusdivneutral{
    background-color: var(--text-color);
}
.statusdivnegative{
    background-color: var(--alert-color);
}
.statusdivpositive{
    background-color: var(--success-color);
}
.statusdivunavailable{
    background-color: var(--inactive-color);
}
/* BARCODE */
.barcode{
    padding:0px;
}

/* TOGGLE */
.toggle{
    background-color:var(--title-background-color);
    padding:10px;
}


/* INFO */
.info{
    text-align: left;
    padding:20px;
}
.infoset{
    display:flex;
    flex-direction: column;
    margin-bottom:20px;
}
.infobig{
    font-size:medium;
    font-weight:bold;
}
.infosmall{
    font-size:small;
}
.infolink{
    font-size:small;
    font-weight:bold;
    text-decoration: underline;
    color:var(--text-color);
}
.popuppic{
    height:150px;
    position:relative;
    cursor:pointer;
}

/* .watermarkimage{
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../assets/WM_PendingReview.png");
  background-size: 100px 50px;
  background-repeat: no-repeat;
  opacity: 0.7;
} */

.watermarked {
  position: relative;
}

.watermarked:after {
  content: "";
  display: block;
  width: 100%;
  height: 50%;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../assets/WM_PendingReview.png");
  background-size: 100px 50px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0.7;
} 

.watermarked2 {
  position: relative;
}

.watermarked2:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("../assets/WM_PendingReview.png");
  background-size: 100px 30px;
  background-position: 100px 130px;
  background-repeat: no-repeat;
  opacity: 0.7;
}
.picButton:hover{
    cursor:pointer;
}
.underlined{
    text-decoration: underline;
}
.clockintext{
    color:rgb(10, 105, 10);
}
.clockinsubtext{
    font-size:small;
}
.clockouttext{
    color:rgb(194, 22, 22);
}
.clockinbackground{
    background-color:rgb(10, 105, 10);
    color:var(--light-text-color);
}
.clockoutbackground{
    background-color:rgb(194, 22, 22);
    color:var(--light-text-color);
}
.eventtext{
    color:black;
}

.eventbuttons{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding:0px;
    margin:0px;
}
.eventbackground{
    background-color:rgb(250, 250, 96);
    color:black;
}
.eventbutton{
   background-color:rgb(250, 250, 96);
    color:black;
    border: none;
    border-radius:10px;
    width:125px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
  text-decoration: none;
}
.eventtext{
    font-size:medium;
    
    color:black;
}
.eventtextdiv{
    background-color:rgb(250, 250, 96);
    padding:5px;
    margin:5px;
}

.editbutton{
  margin:20px 0px 0px 0px;
  background-color:var(--primary-color);
  color:white;
  border:none;
  padding:5px;
}
.editdiv{
  display:flex;
  width:100%;
  flex-direction: row-reverse;
}
.maindiv{
    
    /* overflow-x:hidden;
    overflow-y:auto;
    -webkit-transform: translateZ(0px); 
    -webkit-transform: translate3d(0,0,0); 
    -webkit-perspective: 1000; */
}
.history{
    margin-top:15px;
    display:flex;
    flex-direction: column;
    align-items: center;
}
.historydiv{
    overflow-x:auto;
    overflow-y:auto;
    height:250px;
}
.expandbutton{
    border: none;
    background-color:transparent;
}
.historyitems{
    display:grid;
    grid-template-columns:1fr 1fr 20px;
    grid-template-rows:1fr auto;
    text-align:left;
    font-size:medium;
}
.historydetail{
    grid-row:2;
    -ms-grid-column-span: 2;
}
.historyIn{
    background-color:rgb(10, 105, 10);
    color:white;
}
.historyOut{
    background-color:rgb(194, 22, 22);
    color:white;
}
.historyEvent{
    background-color: rgb(250, 250, 96);
}
.timetext{
    margin:5px;
}

//doc
.docDiv{
    overflow-x:auto;
    overflow-y:scroll;
    height:370px;
}
.documentitems{
    display:grid;
    grid-template-columns:1fr 3fr 1fr;
    text-align:left;
    font-size:medium;
    margin:10px;
}
.doctitle{
    font-weight:bold;

}
.doctitlesigned{
    font-weight:bold;
    color:green;
}
.docbutton{
    height:20px;
    width:30px;
    border:none;
    background-color:transparent;
}
.docimg{
    width:20px;
    background-color:transparent;
    margin:0px;
}
.docimg2{
    width:20px;
    background-color:transparent;
    
}
.col2{
    grid-column: 2;
}
.col3{
    grid-column:3;
    padding-right:10px;
   
}
.required{
    color:red;
}
.signaturebox{
    height:200px;
    width:500px;
    margin:auto;
    background-color:white;
    border:1px solid black;
}
.signlink{
    margin-top:10px;
}
.signlinkrequired{
    margin-top:10px;
    color:red;
}
.pleasewaitdoc{
    color:orangered;
    font-size:large;
}
</style>



             /*  onFileChangedVaccineCard(event){
            this.capturedVaccineCard = event.target.files[0];
            this.capturedVaccineCardURL = URL.createObjectURL(this.capturedVaccineCard);

            this.storeImage(this.badgeData.person.vaccineCardFileName,this.capturedVaccineCard, this.capturedVaccineCard.name,"vaccine")
        }, */

        /* async resizeImage(image, maxDimension){
            const makeImg = image => {
                return new Promise((resolve, _reject) => {
                    const img = new Image();
                    img.onload = function() {
                    resolve(img);
                    };
                    img.src = URL.createObjectURL(image);
                });
            };
            const downscaleDimension = ({ height, width }, limit) => {
                if (height > width && height > limit) {
                    return { height: limit, width: width * (limit / height) };
                } else if (width > height && width > limit) {
                    return { height: height * (limit / width), width: limit };
                } else {
                    return { height, width };
                }
            };
            const renameToJpg = fname => {
                const pos = fname.lastIndexOf(".");
                return fname.substr(0, pos < 0 ? fname.length : pos) + ".jpg";
            };

            return new Promise((resolve, _reject) => {
                makeImg(image).then(img => {
                const newD = downscaleDimension(img, maxDimension);
                const canvas = document.createElement("canvas");
                canvas.width = newD.width;
                canvas.height = newD.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newD.width, newD.height);
                ctx.canvas.toBlob(blob => {
                    resolve(
                    new File([blob], renameToJpg(image.name), {
                        type: "image/jpeg"
                    })
                    );
                }, "image/jpeg");
                });
            });
        }, */

        <!--  <label class="picButton" v-if="allowCaptureLicenseFront" >
                    <div class="picButtonDiv">
                        <input ref="licensefrontinput" type="file" accept="image/*" capture @change="onFileChangedLicenseFront"  style="display:none;">
                        <p>Take Photo of Front</p>      
                    </div>
                </label> -->

                 <!-- <label class="picButton" v-if="allowCapturePerson" >
                            <div class="picButtonDiv">
                                <input ref="selfieinput" type="file" accept="image/*" capture="user" @change="onFileChangedPerson"  style="display:none;">
                                <p>Take Photo</p>      
                            </div>
                        </label> -->

                        <!--  <label class="picButton" v-if="allowCaptureLicenseBack" >
                    <div class="picButtonDiv">
                        <input ref="licensebackinput" type="file" accept="image/*" capture @change="onFileChangedLicenseBack"  style="display:none;">
                        <p>Take Photo of Back</p>      
                    </div>
                </label> -->

                 <!-- <label class="picButton" v-if="allowCaptureVaccineCard" >
                    <div class="picButtonDiv">
                        <input ref="vaccineinput" type="file" accept="image/*" capture @change="onFileChangedVaccineCard"  style="display:none;">
                        <p>Take Photo of Card or Test</p>      
                    </div>
                </label> -->

/* onFileChangedPerson(event){
            this.capturedPerson = event.target.files[0];
            this.capturedPersonURL = URL.createObjectURL(this.capturedPerson);
            
            this.storeImage(this.badgeData.person.personImageFileName, this.capturedPerson, this.capturedPerson.name,"person")
        }, */

        /* onFileChangedLicenseFront(event){
            this.capturedLicenseFront = event.target.files[0];
            this.capturedLicenseFrontURL = URL.createObjectURL(this.capturedLicenseFront);

            this.storeImage(this.badgeData.person.licenseFrontFileName,this.capturedLicenseFront, this.capturedLicenseFront.name,"front")
        }, */

        /*  onFileChangedLicenseBack(event){
            this.capturedLicenseBack = event.target.files[0];
            this.capturedLicenseBackURL = URL.createObjectURL(this.capturedLicenseBack);

            this.storeImage(this.badgeData.person.licenseBackFileName,this.capturedLicenseBack, this.capturedLicenseBack.name,"back")
        }, */