export default{
    data(){
        return{
            //colors
            primaryColor:'#346278',
            highlightColor:'#7EB3D7',
            alertColor:'#E32323',
            textColor:'#346278',
            successColor:'#699864',
            inactiveColor:'#C3C3C3',
            lightTextColor:'#FFFFFF',

            titleTextColor:'#FFFFFF',
            mainTextColor:'#0E2049',
            menuTextColor:'#0E2049',
            titleBackgroundColor:'#7EB3D7',
            mainBackgroundColor:'#E8E8E8',

            mainBackgroundImage:''
        }
    },
    methods:{
        setColors(){
            this.primaryColor = window.PRIMARY_COLOR
            this.highlightColor = window.HIGHLIGHT_COLOR
            this.alertColor=window.ALERT_COLOR
            this.successColor=window.SUCCESS_COLOR
            this.inactiveColor=window.INACTIVE_COLOR
            this.textColor=window.TEXT_COLOR
            this.lightTextColor=window.LIGHT_TEXT_COLOR
            this.titleBackgroundColor= window.HIGHLIGHT_COLOR
            this.mainBackgroundColor=window.BACKGROUND_COLOR
            this.titleTextColor=window.TITLE_TEXT_COLOR
        },
        overrideColors(titleText, mainText, menuText,titleBackground,mainBackground){
            this.titleTextColor =titleText
            this.mainTextColor=mainText
            this.menuTextColor=menuText
            this.titleBackgroundColor=titleBackground   
            this.mainBackgroundColor=mainBackground
        },
        setBackgroundImage(img){
            this.mainBackgroundImage=img;
        }
        
    },
    computed:{
        configStyle(){
            return{
                '--primary-color':this.primaryColor,
                '--highlight-color':this.highlightColor,
                '--alert-color':this.alertColor,
                '--success-color':this.successColor,
                '--inactive-color':this.inactiveColor,
                '--text-color':this.textColor,
                '--light-text-color':this.lightTextColor,

                '--title-text-color':this.titleTextColor,
                '--main-text-color':this.mainTextColor,
                '--menu-text-color':this.menuTextColor,
                '--title-background-color':this.titleBackgroundColor,
                '--main-background-color':this.mainBackgroundColor,
            }
        },
        backgroundurl(){
            console.log(this.mainBackgroundImage)
            if(this.mainBackgroundImage!=null){
                if(this.mainBackgroundImage!=''){
                    return new URL(this.mainBackgroundImage)
                }
            }
               
            
        }
    }
    
}